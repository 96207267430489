.SearchBarPanel {
    display: flex;
    flex-direction: row;
    transition: margin-right 0.4s;
    box-sizing: border-box;
}
.SearchBarPanel.expanded {
    display: flex;
    flex-direction: row;
    margin-right: 350px;
}