.TableLayout {
    width: 100%;
    display: table
}
.TableHeaderContainer {
    width: 100%;
    display: table-header-group;
}
.TableHeader {
    color: #707070;
    font-size: 12px;
    margin-right: 100px;
    display: table-cell;
}
.TableBodyContainer {
    display: table-row-group;
    width: 100%;
    overflow-y: auto;
}
.TableFooterContainer {
    display: flex;
    width: 100%;
    align-content: flex-end;
    justify-content: flex-end;
}