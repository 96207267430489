.Dropdown {
    background-color: #FFFFFF;
    border-radius: 24px;
    height: 40px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333;
    padding: 10px;
    border-style: none;
}