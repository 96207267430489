.LeftPanel {
  width: 20%;
  height: 100%;
  background-color: rgb(247, 247, 247);
}
.RightPanel {
  width: 80%;
  height: 100%;
  background-color: rgb(247, 247, 247);
}
.PanelContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}