.Modal {
    z-index: 1000 !important;
    background-color: white !important;
    border-radius: 20px !important;
    width: 800px !important;
    height: 600px !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 10px 5px 5px black;
    line-height: normal !important;
}