.Tag {
    border: 1px solid #707070;
    border-radius: 28px;
    height: 40px;
    color: #707070;
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}