.TableRow {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin: 3px 0px 3px 0px;
    display: table-row;
    align-items: center;
    line-height: 0;
}
.TableRow > div {
    background-color: #F7F7F7;
    border-bottom: 2px solid white;
    height: 64px;
    display: table-cell;
    vertical-align: middle;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.TableRow > div:first-child {
    border-radius: 32px 0px 0px 32px;
    -moz-border-radius: 32px 0px 0px 32px;
    padding-left: 10px;
}

.TableRow > div:last-child {
    border-radius: 0px 32px 32px 0px; 
    -moz-border-radius: 0px 32px 32px 0px;
}
.TableRowElement {
    display: 'table-cell';
    vertical-align: 'middle';
}
