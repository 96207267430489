.FilterSidePanel {
    position: fixed;
    width: 280px;
    height: 90%;
    background-color: #F7F7F7;
    border: #EDEDED solid 1px;
    border-radius: 24px;
    padding: 20px;
    margin: 10px;
    top: 0px;
    right: -340px;
    transition: right 0.4s;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.FilterSidePanel.expanded {
    right: 0px;
}

.FilterPanelTitle {
    color: #1E90FF;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}