.DashboardContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
}
.Title {
    color: #1E90FF;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: auto;
    margin-left: 25px;
}
.SubTitle {
    margin-top: auto;
    color: #707070;
    font-size: 14px;
    margin-left: 25px;
}
.Dot {
    position: fixed;
    left: 19.8%;
    top: 50%;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #1E90FF 0% 0% no-repeat padding-box;
    border: 2px solid #2FC7F533;
}