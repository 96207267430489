.SubmitButton {
    border-radius: 24px;
    background-color: rgb(30, 144, 255);
    color: white;
    height: 40px;
    width: 272px;
    padding: 8px;
    border: none;
    margin: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    text-decoration: none;
    box-sizing: border-box;
}

.SubmitButton:disabled {
    background-color: #ededed;
    color: black;
}

.SubmitButton.delete {
    background-color: #FFFFFF;
    border: 1px solid #EDEDED;
    color: #FF0000;
}