.SearchInput {
    width: 272px;
    height: 56px;
    display: flex;
    flex-direction: row;
    color: #707070;
    background-color: #F7F7F7;
    border-radius: 28px;
    align-items: center;
    justify-content: space-evenly;
}
.SearchInput input {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
}