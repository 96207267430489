.DeviceTags {
    width: 328px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 24px;
    box-shadow: 0px 16px 16px #00000014;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.DeviceTagsHeader {
    color: #1E90FF;
    font-size: 16px;
    font-weight: 600;
    margin: 15px;
}

.DeviceTagsBody {
    display: flex;
    flex-direction: column;
}

.DeviceTag {
    border: 1px solid #707070;
    border-radius: 28px;
    height: 40px;
    color: #707070;
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}