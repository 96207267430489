.AddButton {
    width: 134px;
    height: 56px;
    box-sizing: border-box;
    border-radius: 28px;
    background-color: #edf6ff;
    color: #1E90FF;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}

.AddButtonPopUp {
    width: 300px;
    position: absolute;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 16px #00000014;
    border-radius: 24px;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    left: 20% + 10px;
    bottom: 15%;
}

.AddButtonPopUp.expanded {
    opacity: 1;
    visibility: visible;
}