.DashboardLayoutContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 60px 30px 60px;
    overflow-y: scroll;
}

.HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 10%;
}

.Header {
    font-size: 24px;
    color: #1E90FF;
    font-weight: 600;
}

.TableContainer {
    width: 100%;
    height: 80%;
}