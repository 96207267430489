.NavContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #707070;
}
.Link {
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 10px;
    margin-left: 35px;
    color: inherit;
    font-weight: 500;
}
.Link.active {
    color: #1E90FF;
    margin-left: 31px;
}
.TabSelected {
    background-color: #1E90FF;
    width: 4px;
    height: 24px;
    border-radius: 29px;
}