.App {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.FilterInputContainer input:not(input[type="checkbox"], input[type="radio"]){
  border-radius: 24px;
  background-color: white;
  color: #808080;
  height: 40px;
  width: 262px;
  border: none;
  margin: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}

form input:not(input[type="checkbox"], input[type="radio"]){ 
  border-radius: 24px;
  background-color: white;
  color: #808080;
  height: 40px;
  width: 262px;
  border: none;
  margin: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}

form input.error {
  border: 1px solid red;
}

select {
  border-radius: 24px;
  background-color: white;
  color: #808080;
  height: 40px;
  width: 262px;
  border: none;
  margin: 5px;
  padding-left: 10px;
}
select.error {
  border: 1px solid red;
}