.UserAvatar {
    width: 40px;
    height: 40px;
}
.UserBadge {
    width: 280px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.BadgeOptions {
    position: fixed;
    transition: bottom 0.4s linear;
    width: 328px;
    height: 150px;
    bottom: -500px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 16px #00000014;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.BadgeOptions.expanded {
    bottom: 70px;
}

.BadgeOptions.passwordchange {
    height: auto;
}