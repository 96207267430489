.BottomDrawer {
    position: fixed;
    height: 125px;
    width: 80%;
    transition: bottom 0.4s;
    bottom: -125px;
    background-color: #FFFFFF;
    padding: 25px;
    box-sizing: border-box;
}
.BottomDrawer.expanded {
    bottom: 0px;
}
.BottomDrawerTitle {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #1E90FF;
}
.Udpatmenu {
    background-color: #FFFFFF;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: visibility 0s, opacity 0.5s linear;
}
.selectAllBtn {
    color: #1E90FF;
    padding: 5px;
    border: 1px solid #1E90FF;
    border-radius: 10px;
    font-weight: bold;
}
.selectAllBtn:hover {
    color: #fff;
    background-color: #1E90FF;
}
.Udpatmenu.expanded {
    opacity: 1;
    visibility: visible;
}